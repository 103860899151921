import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    examCode: '',
    examType: '',
  },
  getters: {},
  mutations: {
    setExamCode(state, examCode){
      state.examCode = examCode
    },
    setExamType(state, examType){
      state.examType = examType
    }
  },
  actions: {},
  modules: {}
})
