import Vue from "vue";
import moment  from "moment-timezone";

Vue.filter('NumberFormat', function(value) {
  if (!value) {
    return '0'
  }
  let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
  return intPartFormat
})

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

/** 字符串超长截取省略号显示 */
Vue.filter('ellipsis', function(value, vlength = 25) {
  if (!value) {
    return "";
  }
  if (value.length > vlength) {
    return value.slice(0, vlength) + '...'
  }
  return value
})


/** 字符串超长截取省略号显示 */
Vue.filter('Number2Option', function(val) {
  val += 1;
  var letter_array = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R",
    "S", "T", "U", "V", "W", "X", "Y", "Z"
  ];
  var output = "";
  while (val > 0) {
    var tmp_var_a = val % 26;
    if (tmp_var_a === 0) {
      tmp_var_a = 26;
    }
    output += letter_array[tmp_var_a - 1];
    val = (val - tmp_var_a) / 26;
  }
  return output;
})
