import Vue from 'vue'
import store from './store'
import router from './router'

const whiteList = ['home','login','preview']
router.beforeEach((to, from, next) => {
    if (whiteList.indexOf(to.name) !== -1) {    // 在白名单或者已授权则放行
        next()
    } else {
        let examCode = to.params.examCode
        let examType = to.params.examType
        store.commit("setExamCode", examCode)
        store.commit("setExamType", examType)
        let token = Vue.ls.get('token_' + examType + "_" + examCode)
        let isAuthenticated = token ? true : false
        if(isAuthenticated){
            next()
        }else{
            let path = "/" + examType + "/" + examCode + "/login"
            next({path: path})
        }
    }
})
