<template>
  <a-modal
      title="查看浏览器兼容性"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @cancel="handleCancel"
  >
    <a-space direction="vertical" style="width: 100%;">
      <a-alert message="IndexDB" :type="val2Type(indexDB)" show-icon/>
      <a-alert message="localStorage" :type="val2Type(localStorage)" show-icon/>
      <a-alert message="history" :type="val2Type(history)" show-icon/>
      <a-alert message="xmlHttpRequest" :type="val2Type(xmlHttpRequest)" show-icon/>
    </a-space>
    <div style="text-align: center; margin-top: 1rem;"><p>若以上任意一项技术不被支持，该浏览器无法用于考试</p></div>
  </a-modal>
</template>

<script>
export default {
  name: "CheckCompatibilityModal",
  data() {
    return {
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      indexDB: 0,
      localStorage: 0,
      history: 0,
      xmlHttpRequest: 0,
    };
  },
  mounted() {
    this.checkCompatibility()
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    checkCompatibility() {
      //IndexDB
      this.indexDB = !window.indexedDB ? 2 : 1;
      //
      this.localStorage = !window.localStorage ? 2 : 1;
      //
      this.history = !(window.history && history.pushState) ? 2 : 1;
      //XMLHttpRequest
      this.xmlHttpRequest = !window.XMLHttpRequest ? 2 : 1;
    },
    val2Type(val) {
      switch (val) {
        case 1:
          return "success"; //成功
        case 2:
          return "error"; //失败
        default:
          return "info";
      }
    },
  },
}
</script>

<style scoped>

</style>
