import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: "/:kaojuanId/preview",
    name: "preview",
    component: () => import("../views/PreviewView")
  },
  {
    path: "/:examType/:examCode",
    redirect: "/:examType/:examCode/wait"
  },
  {
    path: "/:examType/:examCode/login",
    name: "login",
    component: () => import("../views/LoginView")
  },
  {
    path: "/:examType/:examCode/wait",
    name: "wait",
    component: () => import("../views/WaitView")
  },
  {
    path: "/:examType/:examCode/exam",
    name: "exam",
    component: () => import("../views/ExamView")
  },
  {
    path: "/:examType/:examCode/success",
    name: "uploadSuccess",
    component: () => import("../views/UploadSuccessView")
  },
  {
    path: "/:examType/:examCode/end",
    name: "end",
    component: () => import("../views/EndView")
  },
  {
    path: "/:examType/:examCode/*",
    redirect: "/:examType/:examCode"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
